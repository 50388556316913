<template>
  <div class="col-12 px-0 pb-5">
    <div id="clients" class="w-100 clients pageTitle">
      <parallax>
        <img src="/assets/img/krave-studios-header-clients.jpg" alt="" />
      </parallax>
      <h1 class="pageTitle">Clients</h1>
    </div>
    <div class="w-100 clients-container">
      <div class="row pt-5">
        <div class="col-12 col-sm-12 col-md-5 col-lg-5">
          <div class="m-5 p-5">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="2300"
              background="#fff"
              img-width="400"
              img-height="220"
              style="text-shadow: 1px 1px 2px #333"
            >
              <b-carousel-slide
                img-width="80%"
                v-for="image of clientImages"
                :key="image.id"
                :img-src="image.src"
              ></b-carousel-slide>
            </b-carousel>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-7 col-lg-5 d-flex flex-grow-1">
          <div
            class="flex-grow-1 d-flex flex-column justify-content-center align-items-start p-5"
          >
            <p class="client-text p-3">
              Krave produces branded entertainment and scripted content for many
              of the most distinguished names in the fashion, entertainment and
              luxury lifestyle industries.
            </p>
            <p class="client-text p-3">
              We understand how to combine creative, experiential and
              technological solutions to transform a brand’s character into a
              physical, digital and psychological experience.
            </p>
          </div>
        </div>
      </div>
      <div class="row client-list px-4">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
          <div class="row px-4">
            <div class="col-12 py-4">
              <h4 class="clients-title">{{ artists.display }}</h4>
            </div>
            <div
              class="col-12 artist-label"
              v-for="artist of artists.artists"
              :key="artist.id"
            >
              {{ artist.name }}
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="row px-4">
            <div class="col-12 py-4">
              <h4 class="clients-title">{{ brands.display }}</h4>
            </div>
            <div
              class="col-12 col-sm-12 col-md-6 col-lg-6 brands-label"
              v-for="brand of brands.brands"
              :key="brand.id"
            >
              {{ brand.name }}
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3">
          <div class="row px-4">
            <div class="col-12 py-4">
              <h4 class="clients-title">{{ labels.display }}</h4>
            </div>
            <div
              class="col-12 client-labels"
              v-for="label of labels.labels"
              :key="label.id"
            >
              {{ label.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "vue-parallaxy";
import axios from "axios";

export default {
  name: "Clients.vue",
  components: {
    Parallax,
  },
  data: () => {
    return {
      slide: 0,
    };
  },
  computed: {
    footer() { return this.$store.state.footer },
    artists() {  return this.$store.state.artists; },
    brands() {  return this.$store.state.brands; },
    labels() {  return this.$store.state.labels; },
    clientImages() {  return this.$store.state.clientImages; }
  },
  mounted() {
    this.$store.commit("SET_FOOTER", true);
    this.$store.dispatch('getArtists');
    this.$store.dispatch('getBrands');
    this.$store.dispatch('getLabels');
    this.$store.dispatch('getClientImages');
  }
};
</script>
<style scoped>
@import "../css/Clients.scss";
</style>
